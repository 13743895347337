import { useEffect, useState } from 'react';
import config from '../../../configs/aws-config';
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import LinkIcon from '@mui/icons-material/Link';
import { useAuth } from '../../../hooks/use-auth';
import FeedbackDialog from './FeedbackDialog';
import {
  ExpertIngredientAnalysis,
  ExpertProductAnalysis,
} from '../../../types/saved_product_analyses';
import {
  Feedback,
  useAnalysisFeedback,
  useCreateFeedbackMutation,
} from '../../../hooks/ai/ai-feedback-hook';
import { useSegment } from '../../../contexts/segment-context';

type EvaluateWidgetProps = {
  product_analysis_id: string;
  analysis: ExpertIngredientAnalysis | ExpertProductAnalysis;
  account_name: string; // account_name associated with the analysis (not the user)
  show?: boolean;
};

export default function EvaluateWidget({
  analysis,
  product_analysis_id,
  account_name,
  show = true,
}: EvaluateWidgetProps) {
  const [vote, setVote] = useState(null); // [null, 'up', 'down']
  const [feedback, setFeedback] = useState<Feedback>(null);
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const segment = useSegment();
  const { user } = useAuth();
  const thumbColor = undefined; //"white"
  const run_id = analysis.run_id;
  const analysisType = (analysis as ExpertIngredientAnalysis).summary
    ? 'ingredient'
    : 'product_summary';

  const summary =
    (analysis as ExpertIngredientAnalysis).summary ||
    (analysis as ExpertProductAnalysis).product_summary;

  const createMutation = useCreateFeedbackMutation();
  const analysisFeedback = useAnalysisFeedback(product_analysis_id);

  useEffect(() => {
    if (analysisFeedback.data?.feedback) {
      const fb = analysisFeedback.data.feedback.find(
        (f) => f.run_id === run_id,
      );
      if (fb) {
        setFeedback(fb);
        setVote(fb.score === 1 ? 'up' : 'down');
      }
    }
  }, [run_id, analysisFeedback.data]);

  const handleVote = (choice) => {
    const newVote = vote === null ? choice : null;
    setVote(newVote);
    // Post to the backend!
    createMutation.mutate(
      {
        analysis_id: product_analysis_id,
        run_id,
        account_name,
        analysis_type: analysisType,
        original_text: summary,
        score: choice === 'up' ? 1 : -1,
      },
      {
        onSuccess: (data) => {
          setFeedback(data.feedback);
          const isUpVote = newVote === 'up';
          //Send feedback to Segment for an actual choice
          newVote &&
            segment.track('Analysis Feedback Submitted', {
              positive: isUpVote,
              choice: newVote,
              product_analysis_id,
              run_id: analysis.run_id,
              feedback_id: data.feedback?.feedback_id,
              analysis_type: analysisType,
            });
        },
      },
    );
  };

  const handleFeedbackClick = () => {
    setOpenFeedbackDialog(true);
    // segment.track('Feedback Clicked');
  };

  // Don't show anything if we're supposed to be hidden.
  if (!show && !openFeedbackDialog) return null;

  const url = `${config.ai.LANGSMITH_SEARCH_URL}?searchModel={"filter":"eq(id, \\"${run_id}\\")"}&timeModel={"duration":"960d"}`;

  if (
    analysisFeedback.isLoading ||
    analysisFeedback.isFetching ||
    createMutation.isLoading
  ) {
    return <EvaluationLoading />;
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        borderRadius: 1,
        boxShadow: 2,
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{
          width: 'fit-content',
          px: 0.5,
          py: 0.2,
          alignItems: 'center',
          border: '2px solid #DCDCE3',
          borderRadius: '8px',
          bgcolor: 'background.paper',
        }}
      >
        {user && user.role === 'admin' && run_id && (
          <IconButton component={Link} target="_blank" to={url}>
            <LinkIcon sx={{ fontSize: 13 }} />
          </IconButton>
        )}
        {vote === null ? (
          <>
            <IconButton
              color={vote === 'up' ? 'primary' : thumbColor}
              size="small"
              onClick={() => handleVote('up')}
            >
              <ThumbUpIcon sx={{ fontSize: 13 }} />
            </IconButton>
            <IconButton
              color={vote === 'down' ? 'primary' : thumbColor}
              size="small"
              onClick={() => handleVote('down')}
            >
              <ThumbDownIcon sx={{ fontSize: 13 }} />
            </IconButton>
          </>
        ) : (
          <Box>
            {vote === 'up' && (
              <IconButton disabled={true} size="small">
                <ThumbUpIcon sx={{ fontSize: 13 }} />
              </IconButton>
            )}
            {vote === 'down' && (
              <IconButton disabled={true} size="small">
                <ThumbDownIcon sx={{ fontSize: 13 }} />
              </IconButton>
            )}
            <Typography
              onClick={handleFeedbackClick}
              variant="caption"
              sx={{
                color: 'blue',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              Say more
            </Typography>
          </Box>
        )}
        {analysisFeedback.data?.feedback && (
          <FeedbackDialog
            originalText={summary}
            open={openFeedbackDialog}
            onClose={() => setOpenFeedbackDialog(false)}
            account_name={account_name}
            feedback={feedback}
            analysis={analysis}
          />
        )}
      </Stack>
    </Box>
  );
}

const EvaluationLoading = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        borderRadius: 1,
        boxShadow: 2,
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        sx={{
          width: 'fit-content',
          px: 1.5,
          py: 0.8,
          alignItems: 'center',
          border: '2px solid #DCDCE3',
          borderRadius: '8px',
          bgcolor: 'background.paper',
        }}
      >
        <Typography variant="caption">Loading</Typography>
        <CircularProgress size="13px" />
      </Stack>
    </Box>
  );
};
