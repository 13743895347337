import log from 'loglevel';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { Field, Form, Formik } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import * as yup from 'yup';
import ReactMarkdown from 'react-markdown';
import {
  Feedback,
  useUpdateFeedbackMutation,
} from '../../../hooks/ai/ai-feedback-hook';
import { LoadingButton } from '@mui/lab';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../hooks/use-auth';
import {
  ExpertIngredientAnalysis,
  ExpertProductAnalysis,
} from '../../../types/saved_product_analyses';

type FeedbackValues = {
  correctedSummary: string;
  comment: string;
  score: number;
};

type FeedbackDialogProps = {
  open: boolean;
  onClose: () => void;
  account_name: string;
  originalText: string;
  feedback: Feedback;
  analysis: ExpertIngredientAnalysis | ExpertProductAnalysis;
};

export default function FeedbackDialog({
  open,
  onClose,
  account_name,
  originalText,
  feedback,
  analysis,
}: FeedbackDialogProps) {
  const { user } = useAuth();
  const updateMutation = useUpdateFeedbackMutation();

  if ('product_summary' in analysis)
    return <WipFeedbackDialog open={open} onClose={onClose} />;

  const validationSchema = yup.object({
    correctedSummary: yup.string(),
    comment: yup.string(),
    score: yup.number().required('Score is required'),
  });

  const initialValues: FeedbackValues = {
    //correctedSummary: feedback?.correction || '',
    correctedSummary: feedback?.correctionObject?.['summary'] || '',
    comment: feedback?.comment || '',
    score: feedback?.score || 0,
  };

  const handleUpdate = (values) => {
    log.debug('Updating feedback!', values);
    updateMutation.mutate(
      {
        feedback_id: feedback.feedback_id,
        correctionObject: { ...analysis, summary: values.correctedSummary },
        comment: values.comment,
        score: Number(values.score),
      },
      {
        onSuccess: () => {
          onClose();
        },
      },
    );
  };

  const showOwnerSection =
    user?.role === 'admin' ||
    (account_name && user?.active_account?.account_name === account_name) ||
    !!(
      account_name &&
      user?.accounts?.find((a) => a.account_name === account_name)
    );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullWidth={true}
    >
      <DialogTitle id="dialog-title">
        {'Thank you for the feedback!'}
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleUpdate}
        enableReinitialize={true}
      >
        {({ values, setFieldValue, handleChange }) => (
          <Form>
            <DialogContent>
              <Stack direction="column" spacing={2}>
                <Box>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      value={values.score}
                      onChange={handleChange}
                      name="score"
                    >
                      <FormControlLabel
                        value={1}
                        control={
                          <Radio
                            icon={
                              <ThumbUpIcon
                                color="disabled"
                                sx={{ fontSize: 20 }}
                              />
                            }
                            checkedIcon={
                              <ThumbUpIcon
                                color="primary"
                                sx={{ fontSize: 20 }}
                              />
                            }
                          />
                        }
                        label=""
                      />
                      <FormControlLabel
                        value={-1}
                        control={
                          <Radio
                            icon={
                              <ThumbDownIcon
                                color="disabled"
                                sx={{ fontSize: 20 }}
                              />
                            }
                            checkedIcon={
                              <ThumbDownIcon
                                color="primary"
                                sx={{ fontSize: 20 }}
                              />
                            }
                          />
                        }
                        label=""
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <MarkdownBox>{originalText}</MarkdownBox>
                <Field
                  component={FormikTextField}
                  name="comment"
                  label="Feedback"
                  multiline
                  rows={2}
                  helperText="Enter any information you want to share about your choice"
                />
              </Stack>
              {showOwnerSection && (
                <Box sx={{ mt: 2 }}>
                  <Divider sx={{ mb: 2 }} />
                  <Box sx={{ mb: 0, display: 'flex', flexDirection: 'column' }}>
                    <Typography fontSize={20} fontWeight={600}>
                      Owner Section
                    </Typography>
                    <Typography fontSize={14}>
                      This section is ONLY visible to people who manage the
                      account that created the original summary
                    </Typography>
                    <Button
                      size="small"
                      onClick={() =>
                        setFieldValue('correctedSummary', originalText)
                      }
                      sx={{ alignSelf: 'flex-end' }}
                    >
                      Copy Original Text
                    </Button>
                  </Box>
                  <Stack direction="column" spacing={2}>
                    <Field
                      component={FormikTextField}
                      name="correctedSummary"
                      label="Corrected Summary"
                      multiline
                      helperText="The corrected text will be given to the AI as an example to guide it's output in the future."
                    />
                    <Typography fontSize={14}>
                      Does this example point to a generalizable insight? If so,
                      consider adding a "Fact"{' '}
                      <Typography
                        fontSize={14}
                        sx={{
                          color: 'blue',
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        component={Link}
                        to={`/p/${account_name}/facts`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        here
                      </Typography>
                    </Typography>
                  </Stack>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <LoadingButton
                type="submit"
                loading={updateMutation.isLoading}
                disabled={!feedback?.feedback_id}
                variant="contained"
              >
                Submit
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

const WipFeedbackDialog = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullWidth={true}
    >
      <DialogTitle id="dialog-title">
        {'Thank you for the feedback!'}
      </DialogTitle>
      <DialogContent>
        <Typography>Product review feedback is a work in progress!</Typography>
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={onClose} type="submit" variant="contained">
          Ok
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

const MarkdownBox = ({ children }) => {
  return (
    <Box
      sx={{
        '& div.markdown': { fontSize: { xs: 15, sm: 16 } },
        '& p:first-of-type': { marginTop: 0 },
        '& p:last-child': { marginBottom: 0 },
      }}
    >
      <ReactMarkdown className="markdown" linkTarget="_blank">
        {children}
      </ReactMarkdown>
    </Box>
  );
};
